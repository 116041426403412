import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";

import Container from "../../Components/Container";
import { PrimaryButton } from "../../Components/Button";
import Title from "../../Components/Title";
import Arrow from "../../icons/Arrow";
import { data } from "./data";
import SmallCheckbox from "../../Components/SmallCheckbox";

const Section = styled.section`
    height: 100svh;
    width: 100%; 
    overflow-y: auto;
`

const FlexWrapper = styled.div`
    height: 100%;
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
`

const BodyContainer = styled.div`
    margin-bottom: 40px;

    &>*:not(:first-child){
        margin-top: 32px;
    }
    @media (max-width: 768px){
        &>*:not(:first-child){
            margin-top: 16px;
        }
      }
`
const Tags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding-top: 16px;
`

const ButtonContainer = styled.div`
    width: 100%;
    padding-bottom: calc(100vh - 100svh + 30px);
    
    @media (min-width: 768px){
      padding-bottom: 30px;
      display: flex;
      justify-content: flex-end;
    }
`
const QuestionTwo = () => {
    const params = useParams();

    const [language] = useState(params.language);
    const [selectedTags, setSelectedTags] = useState([]);

    const handleTagToggle = (tag) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };

    return (
        <Section>
            <Container>
                <FlexWrapper>
                    <BodyContainer>
                        <Title>{data['title'][language]}</Title>
                        <div>
                            <p>{data['subtitle'][language]}</p>
                            <Tags>
                                {data['tags'][language].map((tag, index) => (
                                    <SmallCheckbox
                                        label={tag}
                                        checked={selectedTags.includes(tag)}
                                        onChange={() => handleTagToggle(tag)}
                                    />
                                ))}
                            </Tags>
                        </div>
                    </BodyContainer>
                    <ButtonContainer>
                        <Link to={`/swipe/${language.toLowerCase()}`}>
                            <PrimaryButton disabled={selectedTags.length === 0}>
                                <>{data['buttonText'][language]}</>
                                <Arrow />
                            </PrimaryButton>
                        </Link>
                    </ButtonContainer>
                </FlexWrapper>
            </Container >
        </Section>

    );
}

export default QuestionTwo;
