import * as React from "react"

const Restart = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={27}
    {...props}
  >
    <path
      d="M11.513 3.81V1.224c0-.65-.776-.967-1.22-.505l-4.021 4.03a.718.718 0 0 0 0 1.025l4.006 4.03a.726.726 0 0 0 1.235-.52V6.698c4.753 0 8.615 3.886 8.615 8.666 0 3.929-2.628 7.25-6.189 8.305-.603.173-.99.75-.99 1.372 0 .939.89 1.675 1.794 1.4a11.474 11.474 0 0 0 5.963-4.154A11.586 11.586 0 0 0 23 15.364C23 8.98 17.86 3.81 11.513 3.81ZM2.897 15.364c0-1.935.632-3.726 1.71-5.185a1.457 1.457 0 0 0-.13-1.892c-.603-.607-1.637-.549-2.154.144A11.606 11.606 0 0 0 .61 19.007a11.551 11.551 0 0 0 2.926 4.667 11.46 11.46 0 0 0 4.746 2.768c.905.274 1.795-.462 1.795-1.401 0-.621-.388-1.199-.99-1.372-3.562-1.055-6.19-4.376-6.19-8.305Z"
    />
  </svg>
)
export default Restart
