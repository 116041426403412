import { useState } from "react";
import styled from "styled-components";
import Container from "../../Components/Container";
import { PrimaryButton } from "../../Components/Button";
import Title from "../../Components/Title";
import Input from "../../Components/Input";
import Email from "../../icons/Email";
import apiClient from "../../services/api";
import useAnswers from "../../hooks/useAnswers";
import { useNavigate, useParams } from "react-router-dom";
import { data } from "./data";

const Section = styled.section`
  height: 100svh;
  width: 100%;
`;

const FlexWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
`;
const BodyContainer = styled.div`
  & > *:not(:first-child) {
    margin-top: 32px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start; /* Align items to the left */
  margin-bottom: 16px;
`;

const BtnTopText = styled.p`
  font-size: 16px;
  font-family: "Anton Regular";
  width: auto;
  //text-transform: uppercase;
`;

const ButtonContainer = styled.div`
width: 100%;

  padding-bottom: 30px;
  display: flex;
  flex-direction: column; /* Stack text and button vertically */
  align-items: flex-start; /* Align items to the left */

  @media (min-width: 768px) {
    align-items: flex-start; /* Ensure left alignment on larger screens */
    width: auto;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
`
const Results = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [language] = useState(params.language);
  const answers = useAnswers();
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    //e.preventDefault();

    const userData = {
      ...answers,
      email,
      firstName,
      language,
    };

    try {
      const response = await apiClient.post("/question-set", userData);
      if (response.data.status === "ok") {
        navigate(`/thankyou/${language}`);
      }
    } catch (error) {
      console.error(
        "Er is een fout opgetreden bij het verzenden van het formulier:",
        error
      );
    }
  };

  return (
    <Section>
      <Container>
        <FlexWrapper>
          <BodyContainer>
            <Title>{data["title"][language]}</Title>
            <p>{data["description"][language]}</p>

            <Form>
              <Input
                label={data["firstName"][language]}
                handleChange={setFirstName}
              />
              <Input label={data["email"][language]} handleChange={setEmail} />
            </Form>
          </BodyContainer>
          <Wrapper>
            <ButtonContainer>
              <TextContainer>
                <BtnTopText>{data["topBtnText"][language]}</BtnTopText>
              </TextContainer>
              <PrimaryButton
                onClick={handleSubmit}
                disabled={
                  firstName.length < 3 ||
                  email.length < 3 ||
                  !email.includes("@")
                }
              >
                <div>{data["buttonText"][language]}</div>
                <Email />
              </PrimaryButton>
            </ButtonContainer>
          </Wrapper>
        </FlexWrapper>
      </Container>
    </Section>
  );
};

export default Results;
