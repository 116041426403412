export const data = {
  title: {
    nl: 'Jouw match',
    en: 'Your match',
    de: 'Dein Match',
    fr: 'TA COMPATIBILTÉ AVEC LILY'
  },
  description: {
    nl: `Vul hieronder jouw gegevens in en 
    ontvang je Leliematch in je mailbox. Nu met het 
    gratis Trouw-inspiratiedossier ‘Breaking 
    Traditions with Lily’.`,
    fr: `Consultez votre boîte aux lettres 
    pour trouver votre Lily. Recevez un fichier 
    gratuit ‘Breaking Traditions with Lily’.`,
    de: `Geben Sie unten Ihre Daten ein 
    und Sie erhalten Ihr Lily Match in Ihrer Mailbox. 
    Jetzt mit kostenloser Hochzeitsinspiration 
    "Breaking Traditions with Lily"`,
    en: `Enter your details below and receive your Lily Match in the post. Now with the free wedding inspiration file 'Breaking Traditions with Lily'.`
  },
  topBtnText: {
    fr: `avec le fichier d'inspiration de mariage gratuit`,
    de: `mit kostenloser Hochzeitsinspirationsdatei`,
    nl: `met gratis Trouw-inspiratiedossier`, 
    en: `with free wedding inspiration file`


  },
  buttonText: {
  nl: 'Stuur het resultaat',
  en: 'Send the result',
  de: 'Ergebnis senden',
  fr: 'Recevoir e résultat'
},
  firstName: {
    nl: 'Voornaam',
    en: 'First Name',
    de: 'Dein Vorname',
    fr: 'Prénom'
  },
  email: {
    nl: 'E-mailadres',
    en: 'Email address',
    de: 'E-Mail Adresse',
    fr: 'Adresse e-mail'
  },
};
