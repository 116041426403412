export const data = {
    title: {
      nl: 'Wat houd je bezig?',
      de: ' WAS BESCHÄFTIGT SIE?',
      fr: 'TU PENSES À QUOI, LÀ?',
      en: 'What\'s on your mind'
    },
    subtitle: {
        nl: '‘Like’ wat je leuk vindt.',
        de: '‘Like’ was du magst.',
        fr: '‘Like’ ce qui te plais.',
        en: '‘Like’ what you like.'
      },
    buttonText: {
      nl: 'Volgende',
      de: 'Weiter',
      fr: 'Suivant',
      en: 'Next'
    },
    tags: {
      nl: [
        'Harry Potter ✨',
        'CrossFit 🏋🏻',
        'Spa & Sauna 🫧',
        'Niet subtiel zijn 🤘🏼',
        '90\'s kid 😅',
        'Heavy metal 🎸',
        'Parfum 🍓',
        'Bitterballen 🥨',
        'Meditatie 🧘🏻‍♀️',
        'Zelfexpressie 👩🏻‍🎤',
        'Slam poëzie 🦋',
        'Hot spots 🛜',
        'Pronken 🕺🏻',
        'Grote maten 🦄',
        'Bloemen 🌸'
      ],
      de: [
        'Harry Potter ✨',
        'CrossFit 🏋🏻',
        'Spa & Sauna 🫧',
        'Nicht subtil sein 🤘🏼',
        '90 Kind 😅',
        'Heavy Metal 🎸',
        'Parfüm 🍓',
        'Bitterballen 🥨',
        'Meditation 🧘🏻‍♀️',
        'Selbstdarstellung 👩🏻‍🎤',
        'Slam-Poesie 🦋',
        'Brennpunkte 🛜',
        'Angeberei 🕺🏻',
        'Große Formate 🦄',
        'Blumen 🌸'
      ],
      fr: [
        'Harry Potter ✨',
        'CrossFit 🏋🏻',
        'Spa détente 🫧',
        'Lourd et rebelle 🤘🏼',
        'Jeune forever 😅',
        'Heavy Metal 🎸',
        'Odeur et senteur🍓',
        'Tapas 🥨',
        'Méditation 🧘🏻‍♀️',
        'Auto-expression 👩🏻‍🎤',
        'Poésie slam 🦋',
        'Hot spots 🛜',
        'Faire ton show 🕺🏻',
        'Grandes tailles 🦄',
        'La magie des fleurs 🌸'
      ],
      en: [
        'Harry Potter ✨',
        'CrossFit 🏋🏻',
        'Spa & Sauna 🫧',
        'Not being subtle 🤘🏼',
        '90\'s kid 😅',
        'Heavy metal 🎸',
        'Perfume 🍓',
        'Bitterballs 🥨',
        'Meditation 🧘🏻‍♀️',
        'Self-expression 👩🏻‍🎤',
        'Slam poetry 🦋',
        'Hot spots 🛜',
        'Show off 🕺🏻',
        'Large sizes 🦄',
        'Flowers 🌸'
      ]
    }
  };
  