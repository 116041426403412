import styled from 'styled-components';


const CheckboxContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ checked }) => checked ? '2px solid #FF6868' : '2px solid transparent'} ; 
  border-radius: 10px;
  background: ${({ checked }) => checked ? '#fff' : 'rgba(255, 255, 255, 0.50)'} ; 
  transition: all 0.3s ease; 
  cursor: pointer;
  box-sizing: border-box;
`;

const StyledCheckboxButton = styled.input.attrs({ type: 'checkbox' })`
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    position: absolute;
`;

const Label = styled.label`
    color: #000;
    text-align: center;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    font-size: clamp(1rem, 0.9123rem + 0.3781vw, 1.125rem);        

`

const LabelContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column; 
    gap: 16px;
`

const Checkbox = ({ label, checked, onChange, Icon }) => {

    return (
        <CheckboxContainer onClick={onChange} checked={checked}>
            <StyledCheckboxButton
                checked={checked}
            />
            <LabelContainer>
                {Icon && Icon}
                <Label>{label}</Label>
            </LabelContainer>
        </CheckboxContainer>
    );
};

export default Checkbox;
