import { Link } from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";

import Container from "../../Components/Container";
import { PrimaryButton } from "../../Components/Button";
import Title from "../../Components/Title";
import Arrow from "../../icons/Arrow";
import Checkbox from "../../Components/Checkbox";
import DutchFlag from "../../icons/DutchFlag";
import GermanFlag from "../../icons/GermanFlag";
import EnglishFlag from "../../icons/FrenchFlag";
import FrenchFlag from "../../icons/FrenchFlag";
import { data } from "./data";

const Section = styled.section`
    height: 100svh;
    width: 100%; 
`

const FlexWrapper = styled.div`
    height: 100%;
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
`
const Body = styled.div`
    &>*:not(:first-child){
        margin-top: 32px;
    }
`
const GridContainer = styled.div`
  height: 60vh;
  width: 100%;
  aspect-ratio: 1/1;
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  grid-template-rows: repeat(2, auto); 
  gap: 10px; 

  @media (max-width: 768px){
    height: 40vh;
  }
`;

const ButtonContainer = styled.div`
    width: 100%;
    padding-bottom: 30px;

    @media (min-width: 768px){
      display: flex;
      justify-content: flex-end;
    }
`
const Languages = () => {
    const [selectedLanguage, setSelectedLanguage] = useState('EN');

    const handleCheckboxChange = (language) => {
        setSelectedLanguage(language);
    };

    return (
        <Section>
            <Container>
                <FlexWrapper>
                    <Body>
                        <Title>{data['title'][selectedLanguage.toLowerCase()]}</Title>
                        <GridContainer>
                            <Checkbox
                                Icon={<EnglishFlag />}
                                label={"English"}
                                checked={selectedLanguage === 'EN'}
                                onChange={() => handleCheckboxChange('EN')}
                            />
                            <Checkbox
                                Icon={<DutchFlag />}
                                label={"Nederlands"}
                                checked={selectedLanguage === 'NL'}
                                onChange={() => handleCheckboxChange('NL')}
                            />
                            <Checkbox
                                Icon={<GermanFlag />}
                                label={'Deutsch'}
                                checked={selectedLanguage === 'DE'}
                                onChange={() => handleCheckboxChange('DE')}
                            />

                            <Checkbox
                                Icon={<FrenchFlag />}
                                label={'Français'}
                                checked={selectedLanguage === 'FR'}
                                onChange={() => handleCheckboxChange('FR')}
                            />

                        </GridContainer>
                    </Body>
                    <ButtonContainer>
                        <Link to={`/intro/${selectedLanguage.toLowerCase()}`}>
                            <PrimaryButton >
                                <>{data['buttonText'][selectedLanguage.toLowerCase()]}</>
                                <Arrow />
                            </PrimaryButton>
                        </Link>
                    </ButtonContainer>
                </FlexWrapper>
            </Container >
        </Section>

    );
}

export default Languages;
