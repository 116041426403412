import React, { useMemo, useRef, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

import useQuestionSet from "../../hooks/useQuestionSet";
import TinderCard from "react-tinder-card";
import RoundButton from "./RoundButton";
import Title from "../../Components/Title";
import Container from "../../Components/Container";

import Heart from "../../icons/Heart";
import Close from "../../icons/Close";
import { useNavigate, useParams } from "react-router-dom";
import SwipeIcon from "../../icons/SwipeIcon";
import MatchModal from "./modals/MatchModal";
import NoMatchModal from "./modals/NoMatchModal";
import useMatchModal from "./hooks/useMatchModal";
import useNoMatchModal from "./hooks/useNoMatchModal";
import useAnswers from "../../hooks/useAnswers";
import { data } from "./data";

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(-45deg);
  }
  75% {
    transform: rotate(45deg);
  }
  100%{
    transform: rotate(0);
  }

`;

const Section = styled.section`
  height: 100svh;
  width: 100%;
  overflow: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  text-align: center;

  z-index: 9999;

  .cardContent {
    width: 100%;
    height: 100%;
  }

  .card h3 {
    width: 100%;
    position: absolute;
    bottom: 30px;
    text-align: center;
    color: #000;
    font-size: 1.75rem;
    z-index: 2;
    color: white;
  }

  @media (max-width: 767px) {
    justify-content: space-between;
    text-align: start;
  }
`;

const IconAnimations = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999999;

  & > * {
    position: absolute;
    opacity: 0;
  }
`;

const SwipeCard = styled(TinderCard)`
  position: absolute;
`;
const Flex = styled.div`
  @media (max-width: 767px) {
    width: 100vw;
    height: 100%;
    position: relative;
    left: -18px;
    top: 8px;
    display: flex;
    justify-content: center;
  }
`;
const CardContainer = styled.div`
  display: flex;
  justify-content: center; /* Om de Card in het midden te plaatsen */
  width: 100%;
  height: 60vh;
  align-items: center;
  overflow: hidden !important;

  padding: 16px;
  z-index: 1;

  @media (max-width: 767px) {
    width: 100%;
    align-items: start;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const Card = styled.div`
  background-color: lightGray;
  width: 52vh;
  height: 52vh;
  //background-image: ${(props) => `url(${props.imageUrl})`};
  //background-size: cover;
  //background-position: center;
  position: relative;
  bottom: ${(props) => 2 * props.index}px;
  right: ${(props) => 2 * props.index}px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 20px;

  img{
    pointer-events: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 20px;
  }

  @media (max-width: 767px) {
    width: 86vw;
    height: 22rem;
  }

  @media (max-width: 376px) {
    width: 86vw;
    height: 43vh;
    max-height: 300px;
  }
`;
const SwipeIconContainer = styled.div`
  position: absolute;
  right: -12px;
  bottom: -18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #333;
  width: 50px;
  aspect-ratio: 1/1;
  border-radius: 100%;
  animation: ${rotateAnimation} 3s ease-in-out;
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
  padding-bottom: 30px;

  @media (max-width: 767px) {
    justify-content: space-between;
  }
`;

const StyledTitle = styled.h2`
  font-size: clamp(2.375rem, 1.849rem + 2.2684vw, 3.125rem);
  letter-spacing: 1px;
  line-height: 1.2;
  font-family: "Anton Regular";
`;

const TinderTool = () => {
  const params = useParams();
  const navigate = useNavigate();
  const matchModal = useMatchModal();
  const noMatchModal = useNoMatchModal();

  const [language] = useState(params.language);
  const { questionSet, isLoading, isError } = useQuestionSet();
  const [cards, setCards] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lastDirection, setLastDirection] = useState();
  const currentIndexRef = useRef(currentIndex);

  //answers
  const answers = useAnswers();

  //modal states
  const [image, setImage] = useState();
  const [title, setTitle] = useState();
  const [text, setText] = useState();
  const [actionlabel, setActionLabel] = useState(data["buttonText"][language]);

  useEffect(() => {
    answers.removeAll();
    noMatchModal.onClose();
    matchModal.onClose();
  }, []);


  const childRefs = useMemo(
    () =>
      Array(questionSet?.data?.cards?.length || 0)
        .fill(0)
        .map(() => React.createRef()),
    [questionSet]
  );

  useEffect(() => {
    if (questionSet?.data?.cards?.length) {
      const { cards } = questionSet.data;
      setCurrentIndex(cards.length - 1);
      setCards(cards);
    }
  }, [questionSet]);

  if (!cards || isLoading) {
    return null;
  }


  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const canSwipe = currentIndex >= 0 && questionSet?.data?.cards;

  const swiped = (direction, id, question, index) => {
    setLastDirection(direction);
    updateCurrentIndex(index - 1);
    //Open match of geen match modal
    if (direction === "left") {
      answers.addAnswer({ [id]: question["left_answer"][language] });

      setImage(question["left_response_image"]);
      setTitle(question["left_response_title"][language]);
      setText(question["left_response_text"][language]);

      setTimeout(() => {
        noMatchModal.onOpen();
      }, 450);
    } else {
      answers.addAnswer({ [id]: question["right_answer"][language] });

      setImage(question["right_response_image"]);
      setTitle(question["right_response_title"][language]);
      setText(question["right_response_text"][language]);

      setTimeout(() => {
        matchModal.onOpen();
      }, 450);
    }
  };

  const outOfFrame = (name, idx) => {
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
  };

  const swipe = async (dir) => {
    if (canSwipe && currentIndex < questionSet.data.cards.length) {
      await childRefs[currentIndex].current.swipe(dir);
    }
  };

  const onSubmitQuestion = () => {
    lastDirection === "left" ? noMatchModal.onClose() : matchModal.onClose();

    if (currentIndex === 0) {
      setActionLabel(data["submitText"][language]);
    }

    if (currentIndex === -1) {
      navigate(`/results/${language}`);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <Section>
      <Container>
        <Wrapper className="swiperPage">
          {currentIndex !== -1 && (
            <StyledTitle>{cards[currentIndex].question[language].toUpperCase()}</StyledTitle>
          )}
          <Flex>
            <CardContainer className="cardContainer">
              {questionSet.data.cards.map((card, index) => (
                <SwipeCard
                  index={index}
                  ref={childRefs[index]}
                  className="swipe"
                  key={card.id}
                  onSwipe={(dir) => swiped(dir, card.id, card, index)}
                  preventSwipe={["up", "down"]}
                  onCardLeftScreen={() => outOfFrame(card.id, index)}
                >
                  <Card imageUrl={card?.image} index={index} className="card">
                    <img src={card?.image} />
                    {index === cards.length - 1 && (
                      <SwipeIconContainer>
                        <SwipeIcon />
                      </SwipeIconContainer>
                    )}
                  </Card>
                </SwipeCard>
              ))}
            </CardContainer>
          </Flex>

          <ButtonContainer className="buttons">
            <RoundButton
              Icon={
                <Close width={"32px"} fill={canSwipe ? "#6A89A8" : "#CACACA"} />
              }
              onClick={() => swipe("left")}
            />
            <RoundButton
              Icon={
                <Heart width={"40px"} fill={canSwipe ? "#44AC45" : "#CACACA"} />
              }
              onClick={() => swipe("right")}
              bgColor="#fff"
            />
          </ButtonContainer>
          <IconAnimations className="animations">
            <Heart width={"80px"} fill={"#44AC45"} />
            <Close width={"80px"} fill={"#6A89A8"} />
          </IconAnimations>
        </Wrapper>
      </Container>
      <MatchModal
        title={title}
        text={text}
        imageUrl={image}
        actionLabel={actionlabel}
        onSubmit={onSubmitQuestion}
      />
      <NoMatchModal
        title={title}
        text={text}
        imageUrl={image}
        actionLabel={actionlabel}
        onSubmit={onSubmitQuestion}
      />
    </Section>
  );
};

export default TinderTool;
