
import styled from "styled-components";
import ThinkingEmoji from '../assets/images/thinking-emoji.png'

const ImageContainer = styled.div`
    height: 50px;
    width: 50px;

    img{
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
`
const Party = () => {
    return (
        <ImageContainer>
            <img src={ThinkingEmoji} />
        </ImageContainer>);
}
 
export default Party;