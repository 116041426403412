import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";

import Container from "../../Components/Container";
import { PrimaryButton } from "../../Components/Button";
import Arrow from "../../icons/Arrow";
import { data } from "./data";

import IntroImage from "../../assets/images/swipe-img.png";
import Title from "../../Components/Title";

const Section = styled.section`
  height: 100svh;
  width: 100%;
  overflow-y: auto;
`;

const FlexWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BodyContainer = styled.div`
  & > *:not(:first-child) {
    margin-top: 40px;
  }

  @media (max-width: 768px) {
    & > *:not(:first-child) {
      margin-top: 24px;
    }
  }
`;

const TextBlocks = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  margin-bottom: 40px;

  h3 {
    font-size: clamp(1rem, 1.0747rem + 0.7561vw, 1.5rem);
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;
    gap: 16px;

    h3 {
      font-size: clamp(1rem, 1.0747rem + 0.7561vw, 1.5rem);
      margin-bottom: 4px;
    }
  }
`;

const ImageContainer = styled.div`
  height: 24vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;

    @media (min-width: 768px) {
      width: auto;
    }
  }

  @media (min-width: 768px) {
    height: 28vh;
    justify-content: flex-start;
  }
`;
const ButtonContainer = styled(Link)`
  width: 100%;
  padding-bottom: calc(100vh - 100svh + 30px);

  @media (min-width: 768px) {
    padding-bottom: 30px;
    display: flex;
    justify-content: flex-end;
  }
`;

const Introduction = () => {
  const params = useParams();
  const [language] = useState(params.language);

  return (
    <Section>
      <Container>
        <FlexWrapper>
          <BodyContainer>
            <Title>{data["title"][language]}</Title>
            <ImageContainer>
              <img src={IntroImage} alt='"A colorful bouquet of roses and an illustrated hand gesture swiping from right to left.'/>
            </ImageContainer>
            <TextBlocks>
              <div>
                <p>{data["description_1"][language]}</p>
              </div>
        
            </TextBlocks>
          </BodyContainer>
          <ButtonContainer to={`/rules/${language.toLowerCase()}`}>
            <PrimaryButton disabled={false}>
              <>{data["buttonText"][language]}</>
              <Arrow />
            </PrimaryButton>
          </ButtonContainer>
        </FlexWrapper>
      </Container>
    </Section>
  );
};

export default Introduction;
