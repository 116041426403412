import useSWR from "swr"

const fetcher = (...args) => fetch(...args).then(res => res.json())

export default function useQuestionSet () {
    const { data, error, isLoading } = useSWR(`https://staging-api.loveforlilies.debo.nl/api/question-set/`, fetcher)
   
    return {
      questionSet: data,
      isLoading,
      isError: error
    }
  }