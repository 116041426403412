import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  background-color: transparent;
  transition: color 0.3s;
  color: #fff;
  font-family: "Open Sans";
  font-size: 16px;
  border-radius: 0;

  &:focus {
    border-bottom-width: 2px;
  }

  @media (min-width: 768px){
    //max-width: 580px; 
  }
`;

const Label = styled.label`
  font-size: 16px;
  color: #fff;
  transition: top 0.3s, font-size 0.3s, color 0.3s;
  font-family: "Open Sans";
`;


const Input = ({ label, value, handleChange, ...rest }) => {
    return (
        <div>
        <Label htmlFor={label}>{label}</Label>
            <StyledInput
                {...rest}
                id={label}
                value={value}
                onChange={(e) => handleChange(e.target.value)}
            />
        </div>
    );
};

export default Input;
