import * as React from "react"

const Arrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={23}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M0 10.33v2.34h17.742a12.266 12.266 0 0 0-3.967 5.139 12.35 12.35 0 0 0-.924 4.691h2.383c0-5.424 4.373-9.821 9.766-9.821V10.32c-5.393 0-9.766-4.397-9.766-9.821h-2.383c0 1.61.314 3.203.924 4.69a12.265 12.265 0 0 0 3.967 5.14H0Z"
      clipRule="evenodd"
    />
  </svg>
)
export default Arrow
