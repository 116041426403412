export const data = {
  title: {
    nl: "Bedankt",
    en: "Thank you",
    de: "Dankeschön",
    fr: "Merci",
  },

  text1: {
    nl: `Check je mailbox voor je Lelie-match! Nu met gratis trouw-inspiratiedossier ‘Breaking Traditions 
    with Lily’`,
    en: `Check your mailbox for 
    your Lily match! Now with free 
    wedding inspiration file 'Breaking 
    Traditions with Lily'.`,
    de: `Schauen Sie in Ihrer 
    Mailbox nach Ihrem Lily-Match! Jetzt 
    mit kostenloser Hochzeitsinspiration 
    'Breaking Traditions with Lily'.`,
    fr: `Consultez votre boîte 
    aux lettres pour trouver votre Lily. 
    Recevez un fichier gratuit ‘Breaking 
    Traditions with Lily’.`,
  },

  buttonText_1: {
    nl: "Herstart Matchmaker",
    en: "Restart matchmaking",
    de: "Matchmaker neu starten",
    fr: "(re)Trouve ton amour de Lily",
  },
  buttonText_2: {
    nl: "Raadpleeg het assortiment",
    en: "Consult the range",
    de: "Das Sortiment konsultieren",
    fr: "Voir tout l'assortiment",
  },
};
