export const data = {
  title: {
    nl: "Ik zoek naar...",
    de: "Ich suche nach",
    fr: "Je recherche",
    en: "I'm searching for"
  },
  answer_1: {
    nl: 'Lange termijn relatie',
    de: 'Langfristige Beziehung',
    fr: 'Une relation au long cours',
    en: 'Long-term relationship'
  },
  answer_2: {
    nl: 'Korte termijn plezier',
    de: 'Kurzfristigen Spaß',
    fr: 'Du fun à court terme',
    en: 'Short-term fun'
  },
  answer_3: {
    nl: 'Ik probeer het nog steeds uit',
    de: 'Ich probiere es noch aus',
    fr: 'Je ne sais pas encore',
    en: 'I\'m still trying it out'
  },
    buttonText: {
    nl: 'Volgende',
    de: 'Weiter',
    fr: 'Suivant',
    en: 'Next'
  },
}
