import axios from 'axios'


const apiClient = axios.create({
    baseURL: 'https://staging-api.loveforlilies.debo.nl/api',
    headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
    }
})

export default apiClient
