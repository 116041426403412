import React from 'react'
import styled, { keyframes } from 'styled-components';

const scaleAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(16);
  }
  100% {
    transform: scale(1);
  }
`;


const Button = styled.button`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor || '#fff'}; 
  z-index: 888;

  
  &:active {
    animation: ${scaleAnimation} 2s ease-in-out;
  }

  @media (max-width: 767px) {
    width: 80px;
    height: 80px;
  }


`

const RoundButton = ({Icon, onClick, bgColor}) => {
  return (
    <Button onClick={onClick} bgColor={bgColor}>
        {Icon || null}
    </Button>
  )
}

export default RoundButton
