import { useEffect } from 'react';

const useFacebookPixel = () => {
  useEffect(() => {
    const loadFacebookPixel = () => {
      const initializeFbPixel = (f, b, e, v, n, t, s) => {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      };

      initializeFbPixel(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      window.fbq('init', '495810273056296');
      window.fbq('track', 'PageView');
    };

    loadFacebookPixel();
  }, []);
};

export default useFacebookPixel;
