import styled from "styled-components";

import Title from "../../Components/Title";
import Container from "../../Components/Container";
import Heart from "../../icons/Heart";
import { PrimaryButton } from "../../Components/Button";
import { SecondaryButton } from "../../Components/Button";
import External from "../../icons/External";
import Restart from "../../icons/Restart";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { data } from "./data";

const Section = styled.section`
  height: 100svh;
  width: 100%;
  display: flex;
`;

const Header = styled.div`
  display: flex;
  gap: 16px;
`;
const HeartIconContainer = styled.div`
  height: 45px;
  width: 45px;
`;
const FlexWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const TextBody = styled.div`
  margin-top: 2rem;
  & > *:not(:first-child) {
    margin-top: 1rem;
  }

  .text2 {
    & > * {
      margin-top: 0.75rem;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 100%;
  padding-bottom: 30px;

  @media (min-width: 768px) {
    padding-bottom: 30px;
    gap: 16px;
    flex-direction: row;
  }
`;

const ButtonExternLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 768px) {
    min-width: 280px;
    gap: 60px;
    width: auto;
  }
`;

const ButtonLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 768px) {
    min-width: 280px;
    gap: 60px;
    width: auto;
  }
`;

const ThankYou = () => {
  const params = useParams();
  const [language] = useState(params.language);

  return (
    <div style={{ minHeight: "100%" }}>
      <Section>
        <Container>
          <FlexWrapper>
            <div>
              <Header>
                <Title>{data["title"][language]}</Title>
                <HeartIconContainer>
                  <Heart fill="#FF6868" />
                </HeartIconContainer>
              </Header>
              <TextBody>
                <p>{data["text1"][language]}</p>
              </TextBody>
            </div>
            <ButtonContainer>
              <SecondaryButton>
                <ButtonLink to={`/intro/${language}`}>
                  <>{data["buttonText_1"][language]}</>
                  <Restart className="icon" />
                </ButtonLink>
              </SecondaryButton>

              <PrimaryButton>
                <ButtonExternLink
                  target="_blank"
                  href="https://www.loveforlilies.com/assortiment"
                >
                  <>{data["buttonText_2"][language]}</>
                  <External />
                </ButtonExternLink>
              </PrimaryButton>
            </ButtonContainer>
          </FlexWrapper>
        </Container>
      </Section>
    </div>
  );
};

export default ThankYou;
