import styled from "styled-components";
import Modal from "../../../Components/Modal/Modal";
import useMatchModal from "../hooks/useMatchModal";

const ModalBody = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: space-between;

  p {
    font-family: "Open Sans";
    font-size: clamp(1rem, 0.9123rem + 0.3781vw, 1.125rem);
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
  }
`;
const ImageContainer = styled.div`
  width: 36vh;
  height: 36vh;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  max-height: 300px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-origin: center;
    background: white;
  }

  @media (max-width: 767px) {
    height: 0;
    width: 100%;
    padding-bottom: 100%;
    img {
      object-fit: cover;
    }
  }
`;

const MatchModal = ({ imageUrl, text, actionLabel, onSubmit }) => {
  const matchModal = useMatchModal();

  const bodyContent = (
    <ModalBody>
      <ImageContainer>
        <img src={imageUrl} />
      </ImageContainer>
      <p>{text}</p>
    </ModalBody>
  );

  return (
    <Modal
      isOpen={matchModal.isOpen}
      title="Yes, you love it!"
      actionLabel={actionLabel}
      body={bodyContent}
      bgColor={"#56C271"}
      onSubmit={onSubmit}
    />
  );
};

export default MatchModal;
