import React, { useCallback, useEffect, useState } from "react";
import { AnimatePresence, motion } from 'framer-motion';

import styled from "styled-components";
import { PrimaryButton } from '../Button'
import { slide } from "./Anim";
import Arrow from "../../icons/Arrow";


const ModalContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  outline: none;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  z-index: 999;

`;

const ModalContent = styled(motion.div)`
  width: 100%;
  height: 100%;
  border: 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: ${({ bgColor }) => bgColor || '#fff'}; 
  outline: none;
  padding: 30px 24px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 767px) {
    border-radius: 20px;
    height: 90vh;
    width: 90vw;
    max-width: 54rem;
    padding: 40px 30px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
`;

const ModalTitle = styled.h2`
  flex: 1;
  font-size: clamp(2.375rem, 1.849rem + 2.2684vw, 3.125rem);
`;

const ModalBody = styled.div`
  margin: 24px 0;

  @media (min-width: 767px) {
    margin: 32px 0;
  }
 
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  `;

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;

const Modal = ({
  isOpen,
  onSubmit,
  title,
  body,
  actionLabel,
  disabled,
  bgColor
}) => {
  const [showModal, setShowModal] = useState(isOpen);

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  const handleSubmit = useCallback(() => {
    if (disabled) {
      return;
    }

    onSubmit();
  }, [onSubmit, disabled]);

  return (
    <AnimatePresence mode="wait">
      {showModal &&
        <ModalContainer >
          <ModalContent
            bgColor={bgColor}
            variants={slide}
            initial="initial"
            animate="enter"
            exit="exit" >
            <div>
              <ModalHeader>
                <ModalTitle>{title.toUpperCase()}</ModalTitle>
              </ModalHeader>
              <ModalBody>{body}</ModalBody>
            </div>
            <ModalFooter>
              <StyledButtonContainer>
                <PrimaryButton
                  disabled={disabled}
                  onClick={handleSubmit}
                >
                  <>{actionLabel}</>
                  <Arrow />
                </PrimaryButton>
              </StyledButtonContainer>
            </ModalFooter>
          </ModalContent>
        </ModalContainer>}
    </AnimatePresence>
  );
};

export default Modal;
