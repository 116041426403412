import "./App.css";
import "./index.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import Languages from "./Pages/Languages/Languages";
import QuestionOne from "./Pages/QuestionOne/QuestionOne";
import QuestionTwo from "./Pages/QuestionTwo/QuestionTwo";
import Results from "./Pages/Results/Results";
import ThankYou from "./Pages/Thankyou/ThankYou";
import TinderTool from "./Pages/TinderTool/TinderTool";
import Introduction from "./Pages/Introduction/Introduction";
import SharedLayout from "./Pages/SharedLayout";
import HouseRules from "./Pages/Houserules/Houserules";
import useFacebookPixel from "./hooks/useFacebookPixel";

function App() {
  //const TRACKING_ID = "G-MKKRJKD4QS" //STAGING
  const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID; //LIVE

  // const googleAnalyticsMeasurementIdStaging = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID_STAGING;
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Page view Homepage",
  });

  useFacebookPixel(); 
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SharedLayout />}>
          <Route index element={<Languages />} />
          <Route path="swipe/:language" element={<TinderTool />} />
          <Route path="intro/:language" element={<Introduction />} />
          <Route path="rules/:language" element={<HouseRules />} />
          <Route path="question/1/:language" element={<QuestionOne />} />
          <Route path="question/2/:language" element={<QuestionTwo />} />
          <Route path="results/:language" element={<Results />} />
          <Route path="thankyou/:language" element={<ThankYou />} />
        </Route>
      </Routes>
      <noscript>
        <img
          height="1"
          width="1"
          src="https://www.facebook.com/tr?id=495810273056296&ev=PageView&noscript=1"
          alt="facebook pixel"
        />
      </noscript>
    </BrowserRouter>
  );
}

export default App;
