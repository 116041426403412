import styled from "styled-components";

const StyledTitle = styled.h2`
    text-transform: uppercase;
    font-size: clamp(2.625rem, 2.2743rem + 1.5123vw, 3.125rem);    
    letter-spacing: 1px;
    line-height: 1.2;
    font-family: 'Anton Regular';
`

const Title = ({ children }) => {
    return (
        <StyledTitle>{children}</StyledTitle>
    );
}

export default Title;