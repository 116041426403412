import * as React from "react"

const DutchFlag = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={26}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="#21468B" d="M.833.5h33.334v25H.833V.5Z" />
      <path fill="#fff" d="M.833.5h33.334v16.667H.833V.5Z" />
      <path fill="#AE1C28" d="M.833.5h33.334v8.333H.833V.5Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.833.5h33.333v25H.833z" />
      </clipPath>
    </defs>
  </svg>
)
export default DutchFlag
