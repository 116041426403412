import * as React from "react"

const Email = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={45}
    height={19}
    fill="none"
    {...props}
  >
    <g
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.407}
      clipPath="url(#a)"
    >
      <path d="M1.931 14.007h11.477M4.39 9.088h9.019M23.546 11.666l-5.925 5.449a2.746 2.746 0 0 1-.317-1.217V1.88M38.342 12.244l5.131 4.722c.15-.328.227-.684.227-1.044V1.827" />
      <path d="m36.876 11.085 6.506 5.977a1.132 1.132 0 0 1-.952.688H18.52a1.253 1.253 0 0 1-.952-.635l6.4-5.872" />
      <path d="m43.7 1.827-8.464 7.776-2.38 2.222c-.106.106-.265.212-.37.317a3.26 3.26 0 0 1-4.074 0c-.105-.105-.264-.211-.37-.317l-2.38-2.169-8.358-7.776c0-.053.052-.053.052-.105a1.08 1.08 0 0 1 .847-.53H42.8a1.165 1.165 0 0 1 .847.477c0 .052.053.052.053.105Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.667 0H45v19H.667z" />
      </clipPath>
    </defs>
  </svg>
)
export default Email
