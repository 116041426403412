import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";

import Container from "../../Components/Container";
import { PrimaryButton } from '../../Components/Button'
import Title from "../../Components/Title";
import Arrow from "../../icons/Arrow";
import Checkbox from "../../Components/Checkbox";
import HeartWithArrow from "../../icons/HeartWithArrow";
import Party from "../../icons/Party";
import Thinking from "../../icons/Thinking";
import { data } from "./data";

const Section = styled.section`
    height: 100svh;
    width: 100%; 
`

const FlexWrapper = styled.div`
    height: 100%;
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
`

const GridContainer = styled.div`
  height: 30vh;
  width: 100%;
  aspect-ratio: 1/1;
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 10px; 
`;

const BodyContainer = styled.div`
    &>*:not(:first-child){
        margin-top: 32px;
    }
`

const ButtonContainer = styled.div`
    width: 100%;
    padding-bottom: 30px;
    
    @media (min-width: 768px){
      display: flex;
      justify-content: flex-end;
    }
`
const QuestionOne = () => {
    const params = useParams();
    const [language] = useState(params.language);
    const [selected, setSelected] = useState(0);

    const handleCheckboxChange = (selected) => {
        setSelected(selected);
    };

    return (
        <Section>
            <Container>
                <FlexWrapper>
                    <BodyContainer>
                    <Title>{data['title'][language]}</Title>
                        <GridContainer>
                            <Checkbox
                                Icon={<HeartWithArrow />}
                                label={data['answer_1'][language]}
                                checked={selected === 0}
                                onChange={() => handleCheckboxChange(0)}
                            />
                            <Checkbox
                                Icon={<Party />}
                                label={data['answer_2'][language]}
                                checked={selected === 1}
                                onChange={() => handleCheckboxChange(1)}
                            />
                            <Checkbox
                                Icon={<Thinking />}
                                label={data['answer_3'][language]}
                                checked={selected === 2}
                                onChange={() => handleCheckboxChange(2)}
                            />
                        </GridContainer>
                    </BodyContainer>
                    <ButtonContainer>
                        <Link to={`/question/2/${language.toLowerCase()}`}>
                            <PrimaryButton >
                                <>{data['buttonText'][language]}</>
                                <Arrow />
                            </PrimaryButton>
                        </Link>
                    </ButtonContainer>
                </FlexWrapper>
            </Container >
        </Section>

    );
}

export default QuestionOne;
