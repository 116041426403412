import * as React from "react"

const External = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29}
    height={28}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m11.38 16.52 1.043 1.043L25.33 4.657a7.683 7.683 0 0 0 .523 4.058c.39.935.96 1.785 1.678 2.502l1.062-1.062c-2.417-2.417-2.428-6.325-.025-8.728l-1.05-1.05c-2.404 2.403-6.311 2.392-8.729-.025l-1.062 1.062a7.783 7.783 0 0 0 2.503 1.678 7.729 7.729 0 0 0 4.057.522L11.38 16.52Z"
      clipRule="evenodd"
    />
    <path stroke="#fff" strokeWidth={3} d="M26 17.5v9H2V2h9" />
  </svg>
)
export default External
