import * as React from "react"

const SwipeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={23}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M13.274.786h.36a52.259 52.259 0 0 1-1.528 2.853c-3.102.036-6.097.62-8.984 1.752-.844.084-1.256-.298-1.235-1.146.064-.24.191-.443.382-.606A29.08 29.08 0 0 1 13.274.786Z"
      clipRule="evenodd"
      opacity={0.992}
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M17.587.786c1.055-.045 1.81.405 2.268 1.348.23.64.2 1.27-.09 1.887a301.412 301.412 0 0 0-2.403 4.312c2.32 1.445 3.916 3.466 4.785 6.065.09.464.09.928 0 1.392-.592 2.104-1.767 3.803-3.527 5.099-2.733 1.839-5.458 1.824-8.176-.045a24.31 24.31 0 0 1-5.862-6.986 43.35 43.35 0 0 1-.853-1.662c-.383-1.235-.016-2.17 1.1-2.807 1.031-.4 1.892-.168 2.583.696.467.677.969 1.328 1.505 1.954.694.356 1.24.199 1.64-.472a805.882 805.882 0 0 1 5.57-9.972c.39-.453.876-.723 1.46-.809Z"
      clipRule="evenodd"
      opacity={0.998}
    />
  </svg>
)
export default SwipeIcon
