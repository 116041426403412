import { create } from 'zustand';

const useAnswers = create((set,get) => ({
  answers: [],
  addAnswer: (answer) => {

    set({
        answers: [...get().answers, answer ]
      }); 
  }, 
  removeAll: ()=>{
    set({
      answers: []
    }); 
  }
}));

export default useAnswers;
