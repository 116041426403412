
import styled from "styled-components";
import PartyEmoji from '../assets/images/party.png'

const ImageContainer = styled.div`
    height: 50px;
    width: 50px;

    img{
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
`
const Party = () => {
    return (
        <ImageContainer>
            <img src={PartyEmoji} />
        </ImageContainer>);
}
 
export default Party;