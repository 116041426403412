export const data = {
  title: {
    nl: "Welkom bij Lily's Matchmaker",
    de: "Willkommen bei Lily's Matchmaker",
    fr: "Bienvenue chez Lily's Matchmaker",
    en: "Welcome to Lily's Matchmaker",
  },
description_1: {
    nl: "Swipe & click. Na 3 vragen vind jij jouw Lelie match.",
    en: "Swipe & click. After 3 questions, you will find your Lily match.",
    de: "Streichen & Klicken. Nach 3 Fragen finden Sie deinen Lily-Match.",
    fr: "Trouve ton amour de Lily en seulement 3 questions."
  },
  buttonText: {
    nl: "Volgende",
    en: "Next",
    de: "Weiter",
    fr: "Suivant",
  },
};
