import * as React from "react"

const GermanFlag = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={26}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="#FFCE00" d="M.833 17.167h33.334V25.5H.833v-8.333Z" />
      <path fill="#000001" d="M.833.5h33.334v8.333H.833V.5Z" />
      <path fill="red" d="M.833 8.833h33.334v8.334H.833V8.833Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.833.5h33.333v25H.833z" />
      </clipPath>
    </defs>
  </svg>
)
export default GermanFlag
