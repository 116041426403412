export const data = {
  title: {
    nl: "Laten we matchen!",
    de: "Lass uns matchen!",
    fr: "Trouvons des correspondances !",
    en: "Let's match!"
  },
  buttonText: {
    nl: 'Volgende',
    de: 'Weiter',
    fr: 'Suivant',
    en: 'Next'
  },
  submitText: {
    nl: 'Indienen',
    de: 'Einreichen',
    fr: 'Soumettre',
    en: 'Submit'
  }
};
