import React from 'react';
import styled from 'styled-components';

const ContainerWrapper = styled.div`
    height: 100%;
    max-width: 58rem;
    margin: 0 auto;
    padding: 40px 30px; 
    box-sizing: border-box;

    @media (max-width: 768px) {
        padding: 24px; 
    }
`;

const Container = ({ children }) => {
    return (
        <ContainerWrapper>
            {children}
        </ContainerWrapper>
    );
};

export default Container;