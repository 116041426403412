import * as React from "react"

const FrenchFlag = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={26}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="#fff" d="M.833.5h33.334v25H.833V.5Z" />
      <path fill="#000091" d="M.833.5h11.11v25H.833V.5Z" />
      <path fill="#E1000F" d="M23.057.5h11.11v25h-11.11V.5Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.833.5h33.333v25H.833z" />
      </clipPath>
    </defs>
  </svg>
)
export default FrenchFlag
