export const data = {
  title: {
    en: 'Choose your language',
    nl: 'Kies jouw taal',
    de: 'Wähle deine Sprache',
    fr: 'Choisissez votre langue'
  },
  answer_1: {
    nl: 'Engels',
    en: 'English',
    de: 'Englisch',
    fr: 'Anglais'
  },
  answer_2: {
    nl: 'Nederlands',
    en: 'Dutch',
    de: 'Niederländisch',
    fr: 'Néerlandais'
  },
  answer_3: {
    nl: 'Duits',
    en: 'German',
    de: 'Deutsch',
    fr: 'Allemand'
  },
  answer_4: {
    nl: 'Frans',
    en: 'French',
    de: 'Französisch',
    fr: 'Français'
  },
  buttonText: {
    nl: 'Volgende',
    en: 'Next',
    de: 'Weiter',
    fr: 'Suivant'
  }
};
