import styled from 'styled-components';


const CheckboxContainer = styled.div`
  position: relative;
  width: auto;
  height: 100%;
  display: inline-flex;
  border: ${({ checked }) => checked ? '2px solid #FF6868' : '2px solid transparent'} ; 
  border-radius: 10px;
  background: #fff;
  transition: border-color 0.3s ease; 
  cursor: pointer;  
  padding: 20px 32px;
  box-sizing: border-box;

  @media (max-width: 768px){
    padding: 18px 26px; 
  }

  @media (max-width: 467px){
    padding: 10px 8px; 
  }
`;

const StyledCheckboxButton = styled.input.attrs({ type: 'checkbox' })`
   appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    position: absolute;
    left: 0;
    top: 0;
`;

const Label = styled.label`
    color: #000;
    text-align: center;
    font-family: "Open Sans";
    font-size: clamp(1rem, 0.7808rem + 0.9452vw, 1.3125rem);    
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
`

const LabelContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column; 
    gap: 10px;
    cursor: pointer;

`

const SmallCheckbox = ({ label, checked, onChange, Icon }) => {

    return (
        <CheckboxContainer onClick={onChange} checked={checked}>
            <StyledCheckboxButton
                checked={checked}
            />
            <LabelContainer>
                {Icon && Icon}
                <Label>{label}</Label>
            </LabelContainer>
        </CheckboxContainer>
    );
};

export default SmallCheckbox;
