import { createGlobalStyle } from "styled-components";
import AntonRegular from '../assets/fonts/Anton-Regular.ttf'
import Edo from '../assets/fonts/edo.ttf'

export const GlobalStyle = createGlobalStyle`


*,*::before,*::after{
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

html, 
body, 
:root{
  height: 100%;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Anton Regular';
    overflow: hidden;
    background: #7D92B1;
    color: #FFFFFF;
}

h1, h2, h3, h4, h5, h6{
    margin: 0; 
    padding: 0;
}

p{
    margin: 0; 
    padding: 0; 
    font-family: "Open Sans";
    font-size: clamp(1rem, 0.9123rem + 0.3781vw, 1.125rem);        
    font-style: normal;
    font-weight: 400;
    line-height: 1.3; 
}
a {
    color: inherit;
    text-decoration: inherit;

}

@font-face {
    font-family: 'Anton Regular';
    src: url(${AntonRegular}) format("truetype") ;
}

@font-face {
    font-family: 'Edo';
    src: url(${Edo}) format("truetype") ;
}

`