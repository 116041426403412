import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";

import Container from "../../Components/Container";
import { PrimaryButton } from "../../Components/Button";
import Arrow from "../../icons/Arrow";
import { data } from "./data";

import Title from "../../Components/Title";

const Section = styled.section`
    height: 100svh;
    width: 100%; 
    overflow-y: auto;
`

const FlexWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
  
`

const BodyContainer = styled.div`
    &>*:not(:first-child){
        margin-top: 40px;
    }

    @media (max-width: 768px){
        &>*:not(:first-child){
            margin-top: 24px;
        }
    }
`

const TextBlocks = styled.div`
    display: flex; 
    gap: 24px;
    flex-direction: column; 
    margin-bottom: 40px;

    h3 {
        font-size: clamp(1rem, 1.0747rem + 0.7561vw, 1.5rem);
        margin-bottom: 10px;
    }

    @media (max-width: 768px){
        margin-bottom: 30px;
        gap: 16px;

        h3 {
            font-size: clamp(1rem, 1.0747rem + 0.7561vw, 1.5rem);
            margin-bottom: 4px;
        }
    }
`

const ButtonContainer = styled(Link)`
    width: 100%;
    padding-bottom: calc(100vh - 100svh + 30px);
    
    @media (min-width: 768px){
      padding-bottom: 30px;
      display: flex;
      justify-content: flex-end;
    }
`

const HouseRules = () => {
    const params = useParams();
    const [language] = useState(params.language);

    return (
        <Section>
            <Container>
                <FlexWrapper>
                    <BodyContainer>   
                        <Title>{data['title'][language]}</Title>                    
                        <TextBlocks>
                            <div>
                            <h3>{data['title_1'][language].toUpperCase()}</h3>
                                <p>{data['description_1'][language]}</p>
                            </div>
                            <div>
                                <h3>{data['title_2'][language].toUpperCase()}</h3>
                                <p>{data['description_2'][language]}</p>
                            </div>
                            <div>
                                <h3>{data['title_3'][language].toUpperCase()}</h3>
                                <p>{data['description_3'][language]}</p>
                            </div>
                        </TextBlocks>
                    </BodyContainer>
                    <ButtonContainer to={`/question/1/${language.toLowerCase()}`}>
                        <PrimaryButton disabled={false}>
                            <>{data['buttonText'][language]}</>
                            <Arrow />
                        </PrimaryButton>
                    </ButtonContainer>
                </FlexWrapper>
            </Container >
        </Section>
    );
}

export default HouseRules;
