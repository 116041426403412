import styled from 'styled-components';

const BaseButton = styled.button`
  width: 100%;
  color: white;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Anton Regular';
  font-size: clamp(1.3125rem, 1.0933rem + 0.9452vw, 1.625rem);
  text-transform: uppercase;
  padding: 18px;
  transition: background-color 0.3s ease;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  @media (min-width: 768px){
    min-width: 280px; 
    gap: 60px;
    width: auto;
  }
`;

const PrimaryButton = styled(BaseButton)`

  background-color: ${props => (props.disabled ? '#CCCCCC' : '#000')};
  border: ${props => (props.disabled ? '1px solid #CCCCCC' : '1px solid #000')};

  &:hover {
    border: ${props => (props.disabled ? '1px solid #CCCCCC' : '1px solid #222')};
    background-color: ${props => (props.disabled ? '#CCCCCC' : '#222')};
  }
`;

const SecondaryButton = styled(BaseButton)`
  background: transparent;
  color: #fff;
  border: 1px solid #fff;

  svg {
      fill: #fff !important;
  }

  &:hover {
    background-color: ${props => (props.disabled ? 'gray' : '#fff')};
    color: #000;

    svg {
      fill: #000 !important;
    }
  }
`;

export { PrimaryButton, SecondaryButton };