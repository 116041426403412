export const data = {
    title: {
        nl: 'Huisregels',
        en: 'House Rules',
        de: 'Hausregeln',
        fr: 'Les règles du jeu'
      },
    subtitle: {
        nl: 'Volg deze huisregels',
        de: 'Befolge diese Hausregeln',
        fr: 'Suivez les règles de la maison',
        en: 'Follow these house rules'
    },
    buttonText: {
        nl: 'Start matchmaking',
        en: 'Start matchmaking',
        de: 'Matchmaking starten',
        fr: 'Trouve ton amour de Lily'
      },
    title_1: {
        nl: 'Ben jezelf',
        de: 'Sei du selbst',
        fr: 'Sois toi-même',
        en: 'Be Yourself'
    },
    description_1: {
        nl: 'Blijf dicht bij jezelf; mooier maken dan de werkelijkheid hoeft echt niet.',
        de: 'Bleib dir selbst treu; es gibt wirklich keinen Grund, sich zu verschönern.',
        fr: 'Reste proche de toi-même, pas besoin de faire des manières.',
        en: 'Stay close to yourself; there\'s really no need to prettify.'
    },
    title_2: {
        nl: 'Speel het met plezier',
        de: 'Spiel es mit Freude',
        fr: 'Joue la carte du plaisir',
        en: 'Play it with pleasure'
    },
    description_2: {
        nl: 'Neem het niet te serieus. Lol maken mag!',
        de: 'Nimm es nicht zu ernst. Spaß haben ist erlaubt!',
        fr: 'Ne prends pas les choses trop au sérieux. Il est permis de s\'amuser!',
        en: 'Don\'t take it too seriously. Having fun is allowed!'
    },
    title_3: {
        nl: 'Wees proactief',
        de: 'Sei proaktiv',
        fr: 'Sois proactif',
        en: 'Be proactive'
    },
    description_3: {
        nl: 'Het gaat allemaal niet vanzelf; jouw unieke wens maakt het verschil.',
        de: 'Es passiert nicht alles von selbst; dein einzigartiger Wunsch macht den Unterschied.',
        fr: 'Rien n\'arrive par hasard, c\'est ce que tu veux toi, qui compte!',
        en: 'It doesn\'t all happen by itself; your unique desire makes all the difference.'
    }
};
