import styled from "styled-components";

import LogoIcon from "../icons/Logo";

const ContainerWrapper = styled.div`
    height: 100%;
    max-width: 58rem;
    margin: 0 auto;
    box-sizing: border-box;

    @media (max-width: 768px) {
        display: flex;
        justify-content: flex-end;
    }
`;

const LogoContainer = styled.a`
    cursor: pointer;
    margin-right: 30px;

    @media (min-width: 768px) {
        margin-right: 0;
        margin-left: 30px;


    }
  }
`

const Logo = () => {
    return (
        <ContainerWrapper>
            <LogoContainer href='https://www.loveforlilies.com/' target='_blank'>
                <LogoIcon />
            </LogoContainer>
        </ContainerWrapper>
    );
}

export default Logo;